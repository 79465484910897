











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import ChartResult from '../../components/charts/chart-result.vue';
import { ResultModule } from '../../store/modules/result-module';
import { ModuleModule } from '../../store/modules/module-module';
import { ChartUtil } from '../../utils/chart';

@Component({
  components: { ChartResult },
})
export default class DashboardOverview extends Vue {
  @Prop({ type: Boolean, default: false })
  public paid!: boolean;

  public module = getModule(ModuleModule);
  public result = getModule(ResultModule);

  public get dashboard() {
    return this.result.dashboard.find(
      d => d.elementId === this.$route.params.eid
    );
  }

  public get priorities() {
    return this.$route.params.eid === 'priorities';
  }

  public getPriorityData(type: string) {
    const impQ = this.module.findDashboard('subject-importance');
    const satQ = this.module.findDashboard('subject-satisfaction');
    if (!impQ || !satQ) return undefined;

    const imp = this.result.dashboard.find(r => r.elementId === impQ.id);
    const sat = this.result.dashboard.find(r => r.elementId === satQ.id);
    if (!imp || !sat) return undefined;

    const [c1, c2, c3] = ChartUtil.color(3);

    const impD = this.parseDataset(imp.dataset as any);
    const satD = this.parseDataset(sat.dataset as any);
    const avgD = impD.map((v, i) => v - satD[i]);

    const max =
      (imp.dataset as any)[0][0] +
      (imp.dataset as any)[0][1] +
      (imp.dataset as any)[0][2] +
      (imp.dataset as any)[0][3] +
      (imp.dataset as any)[0][4];

    const sorting = avgD
      .map((v, i) => ({ v, i }))
      .sort((a, b) => b.v - a.v)
      .map(s => s.i);
    const sort = (arr: any[]) => sorting.map(i => arr[i]);

    return {
      datasets: [
        this.toDataset(sort(impD), max, c1, 'Belang'),
        this.toDataset(sort(satD), max, c2, 'Tevredenheid'),
        this.toDataset(sort(avgD), max, c3, 'Belang -/- Tevredenheid'),
      ],
      labels: sort(imp.labels),
    };
  }

  public getMax(dataset: any) {
    return Math.max(
      ...Object.keys(dataset).map(key => Math.max(...(dataset as any)[key]))
    );
  }

  public getPriorityOptions(type: string) {
    return (
      type === 'bar' && {
        plugins: { legend: { display: true } },
        scales: { yAxes: { beginAtZero: false, max: 1, min: -1 } },
      }
    );
  }

  private parseDataset(dataset: { [key: number]: number[] }): number[] {
    return Object.keys(dataset).reduce((acc, _, i) => {
      acc[i] = dataset[i][3] + dataset[i][4];
      return acc;
    }, [] as number[]);
  }

  private toDataset(data: number[], max: number, color: string, label: string) {
    return {
      backgroundColor: color,
      data: data.map(v => v / max),
      label,
      minBarLength: ChartUtil.minBarLength,
    };
  }
}
