













































































import { DashboardResult } from '@app/models';
import { BlockElement } from '@bcase/module-editor';
import { ChartData, ChartOptions } from 'chart.js';
import _ from 'lodash';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { ChartType } from '../../models/chart-type';
import { ChartUtil } from '../../utils/chart';
import ChartBar from './chart-bar.vue';
import ChartDoughnut from './chart-doughnut.vue';

@Component({
  components: { ChartBar, ChartDoughnut },
})
export default class ChartResult extends Vue {
  @Prop({ type: Boolean, default: false })
  public blur!: boolean;

  @Prop({ type: Function })
  public getChartData?: (type: string) => any;

  @Prop({ type: Function })
  public getChartOptions?: (type: string) => any;

  @Prop({ type: String })
  public icon?: string;

  @Prop({ type: Object, required: false })
  public result?: DashboardResult;

  public chart: ChartType = 'bar';

  public get chartData(): ChartData<ChartType> {
    if (this.getChartData) {
      const data = this.getChartData(this.chart);
      if (data) return data;
    }

    return this.result
      ? ChartUtil.toChartData(this.chart, this.result)
      : { datasets: [] };
  }

  public get chartOptions(): ChartOptions<ChartType> {
    if (this.getChartOptions) {
      const options = this.getChartOptions(this.chart);
      if (options) return options;
    }

    if (!this.result) return {};

    // Create title with max 80 character per line
    const title = (this.result.question as string)
      .split(' ')
      .reduce<string[]>((acc, word) => {
        if (acc.length == 0) return [word];

        const last = acc.length - 1;
        const combined = acc[last] + ' ' + word;

        return combined.length > 80
          ? [...acc, word]
          : [...acc.slice(0, -1), combined];
      }, []);

    const override: ChartOptions<ChartType> = {
      plugins: {
        title: {
          display: true,
          font: { size: 16 },
          color: '#000',
          text: title,
          padding: 32,
        },
      },
    };

    return _.merge(ChartUtil.toChartOptions(this.chart, this.result), override);
  }

  public get questionIcon() {
    if (this.icon) return this.icon;
    return this.result ? BlockElement.icon(this.result.type) : '';
  }

  public get isChart() {
    return (
      !this.result ||
      (this.result.type !== 'icon' && this.result.type !== 'input')
    );
  }

  public get title() {
    return this.isChart
      ? ''
      : (this.chartOptions.plugins!.title!.text as string[]).join(' ');
  }

  public getSmileyPercentage(results: number[], i: number) {
    return results[i] == 0
      ? 0
      : Math.round((results[i] / results.reduce((a, v) => a + v)) * 100);
  }

  public print(e: Event) {
    if (this.blur) return;
    e.stopPropagation();

    const canvas = this.$el.querySelector('canvas') as HTMLCanvasElement;
    const link = document.createElement('a');
    link.download = 'image';
    link.href = canvas.toDataURL('image/png');
    link.click();
  }

  public setChart(event: Event, chart: ChartType) {
    event.stopPropagation();
    this.chart = chart;
  }
}
